import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Container,
  Grid,
} from "@mui/material";
import ImagesTabComponent from "./GalleryTabs/ImagesTab";
import VideosTabComponent from "./GalleryTabs/VideosTab";
import SettingsTabComponent from "./GalleryTabs/SettingsTab";
import axios from "axios";
import { getToken } from "../../services/authentication";
import { getActiveCompany } from "../../services/localStorage";
import { MediaItem } from "../../models/media";
import { useTranslation } from "react-i18next";
import { getTvLimit } from "../../helpers/common";

const API_URL = process.env.REACT_APP_API_URL;
const tvIcon = process.env.PUBLIC_URL + "/assets/images/settings/tv.png";

interface SettingsData {
  splitScreenOptions: number[];
  rememberPin: boolean;
  wifiSettings: {
    ssid: string;
    password: string;
  };
}

const GalleryComponent: React.FC = () => {
  const [selectedTv, setSelectedTv] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const [companyMedia, setCompanyMedia] = useState<MediaItem[]>([]);
  const [forceRefresh, setForceRefresh] = useState<boolean>(false);
  const [tvIds, setTvIds] = useState<number[]>([]);
  const [initialSettings, setInitialSettings] = useState<SettingsData>({
    splitScreenOptions: [],
    rememberPin: false,
    wifiSettings: { ssid: '', password: '' }
  });

  const { t } = useTranslation();

  const handleTvChange = (event: any, newValue: number) => {
    setSelectedTv(newValue);
  };

  const handleTabChange = (event: any, newValue: number) => {
    setSelectedTab(newValue);
  };

  const getCompanyImages = () => {
    return companyMedia.filter(
      (media) => media.mediaType === "image" && media.tvId === tvIds[selectedTv]
    );
  };

  const getCompanyVideos = () => {
    return companyMedia.filter(
      (media) => media.mediaType === "video" && media.tvId === tvIds[selectedTv]
    );
  };

  const refreshImages = () => {
    setForceRefresh(!forceRefresh);
  };

  const handleSplitScreenOptionsUpdate = (newOptions: number[]) => {
    setInitialSettings(prev => ({
      ...prev,
      splitScreenOptions: newOptions
    }));
  };

  const getCompanyMedia = useCallback(async (): Promise<void> => {
    try {
      const token: string = getToken();
      const company = getActiveCompany();
      if (!company) return;
      const companyId = company.id;
      const tvs = company.tvs.sort((a, b) => a.id - b.id);
      const tvIds = tvs.map((tv) => tv.id);
      const splitScreenOptions = tvs.map((tv) => tv.splitScreenOption);
      const rememberPinOption = tvs.map((tv) => tv.rememberPin);
      const selectedTvWifiSettings = tvs[selectedTv]?.wifiSettings;

      const response = await axios.get<{ mediaItems: MediaItem[] }>(
        `${API_URL}/media/getAllMediaForCompany/${companyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const mediaItems = response.data.mediaItems;
      setCompanyMedia(mediaItems);

      // TODO: Tv limits need to work in order to limit
      // Right now we are showing all the tvs to the customer
      const tvLimits = getTvLimit(
        company.subscriptionPlanId,
        company.customLimits
      );

      console.log(tvLimits)

      setTvIds(tvIds);
      setInitialSettings({
        splitScreenOptions,
        rememberPin: rememberPinOption[selectedTv] || false,
        wifiSettings: {
          ssid: selectedTvWifiSettings?.ssid || '',
          password: selectedTvWifiSettings?.password || ''
        }
      });
    } catch (error: any) {
      console.error("Error fetching company media:", error);
    }
  }, [selectedTv]);

  useEffect(() => {
    getCompanyMedia();
  }, [forceRefresh, getCompanyMedia]);

  return (
    <Container sx={{ mt: 5 }}>
      <Grid container spacing={2} direction="column">
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ color: "primary.main", mb: 2 }}>
            {t("galleryComponent_title")}
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
            <Tabs
              value={selectedTv}
              onChange={handleTvChange}
              indicatorColor="primary"
              textColor="inherit"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="TV Tabs"
              sx={{
                ".MuiTabs-flexContainer": {
                  backgroundColor: "transparent",
                },
                ".MuiTab-root": {
                  color: "grey",
                  "&.Mui-selected": {
                    color: "white",
                  },
                },
              }}
            >
              {tvIds.map((tvId, index) => (
                <Tab
                  key={tvId}
                  label={
                    <Box component="span">
                      <img
                        src={tvIcon}
                        alt={`TV ${index + 1}`}
                        style={{ width: 24, height: 24 }}
                      />
                    </Box>
                  }
                  sx={{ textTransform: "none", fontWeight: "bold" }}
                />
              ))}
            </Tabs>
          </Box>

          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="Gallery Tabs"
            sx={{
              ".MuiTabs-flexContainer": {
                backgroundColor: "transparent",
              },
              ".MuiTab-root": {
                color: "grey",
                "&.Mui-selected": {
                  color: "white",
                },
              },
            }}
          >
            <Tab
              label={t("galleryComponent_tabImages")}
              sx={{ textTransform: "none", fontWeight: "bold" }}
            />
            <Tab
              label={t("galleryComponent_tabVideos")}
              sx={{ textTransform: "none", fontWeight: "bold" }}
            />
            <Tab
              label={t("galleryComponent_tabSettings")}
              sx={{ textTransform: "none", fontWeight: "bold" }}
            />
          </Tabs>

          <Box sx={{ p: 2 }}>
            {selectedTab === 0 && (
              <ImagesTabComponent
                refreshImages={refreshImages}
                data={getCompanyImages()}
                tvId={tvIds[selectedTv]}
              />
            )}
            {selectedTab === 1 && (
              <VideosTabComponent
                refreshVideos={refreshImages}
                data={getCompanyVideos()}
                tvId={tvIds[selectedTv]}
              />
            )}
            {selectedTab === 2 && (
              <SettingsTabComponent
                selectedTv={selectedTv}
                tvIds={tvIds}
                splitScreenOptions={initialSettings.splitScreenOptions}
                rememberPin={initialSettings.rememberPin}
                wifiSettings={initialSettings.wifiSettings}
                onSplitScreenOptionsUpdate={handleSplitScreenOptionsUpdate}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default GalleryComponent;