import React from 'react';
import { Button } from '@mui/material';

const LayoutButton = ({
    layout,
    isActive,
    onClick
}: {
    layout: 'single' | '2x2' | '3x3' | '2:1',
    isActive: boolean,
    onClick: () => void
}) => {
    const getLayoutIcon = () => {
        switch (layout) {
            case 'single':
                return (
                    <rect
                        x="4"
                        y="4"
                        width="24"
                        height="24"
                        rx="2"
                        fill={isActive ? "#000" : "none"}
                        stroke={isActive ? "#000" : "currentColor"}
                        strokeWidth="2"
                    />
                );
            case '2x2':
                return (
                    <>
                        <rect x="4" y="4" width="11" height="11" rx="1" fill={isActive ? "#000" : "none"} stroke={isActive ? "#000" : "currentColor"} strokeWidth="2" />
                        <rect x="17" y="4" width="11" height="11" rx="1" fill={isActive ? "#000" : "none"} stroke={isActive ? "#000" : "currentColor"} strokeWidth="2" />
                        <rect x="4" y="17" width="11" height="11" rx="1" fill={isActive ? "#000" : "none"} stroke={isActive ? "#000" : "currentColor"} strokeWidth="2" />
                        <rect x="17" y="17" width="11" height="11" rx="1" fill={isActive ? "#000" : "none"} stroke={isActive ? "#000" : "currentColor"} strokeWidth="2" />
                    </>
                );
            case '3x3':
                return (
                    <>
                        <rect x="4" y="4" width="7" height="7" rx="1" fill={isActive ? "#000" : "none"} stroke={isActive ? "#000" : "currentColor"} strokeWidth="2" />
                        <rect x="13" y="4" width="7" height="7" rx="1" fill={isActive ? "#000" : "none"} stroke={isActive ? "#000" : "currentColor"} strokeWidth="2" />
                        <rect x="22" y="4" width="7" height="7" rx="1" fill={isActive ? "#000" : "none"} stroke={isActive ? "#000" : "currentColor"} strokeWidth="2" />
                        <rect x="4" y="13" width="7" height="7" rx="1" fill={isActive ? "#000" : "none"} stroke={isActive ? "#000" : "currentColor"} strokeWidth="2" />
                        <rect x="13" y="13" width="7" height="7" rx="1" fill={isActive ? "#000" : "none"} stroke={isActive ? "#000" : "currentColor"} strokeWidth="2" />
                        <rect x="22" y="13" width="7" height="7" rx="1" fill={isActive ? "#000" : "none"} stroke={isActive ? "#000" : "currentColor"} strokeWidth="2" />
                        <rect x="4" y="22" width="7" height="7" rx="1" fill={isActive ? "#000" : "none"} stroke={isActive ? "#000" : "currentColor"} strokeWidth="2" />
                        <rect x="13" y="22" width="7" height="7" rx="1" fill={isActive ? "#000" : "none"} stroke={isActive ? "#000" : "currentColor"} strokeWidth="2" />
                        <rect x="22" y="22" width="7" height="7" rx="1" fill={isActive ? "#000" : "none"} stroke={isActive ? "#000" : "currentColor"} strokeWidth="2" />
                    </>
                );
            case '2:1':
                return (
                    <>
                        <rect x="4" y="4" width="16" height="24" rx="1" fill={isActive ? "#000" : "none"} stroke={isActive ? "#000" : "currentColor"} strokeWidth="2" />
                        <rect x="22" y="4" width="8" height="24" rx="1" fill={isActive ? "#000" : "none"} stroke={isActive ? "#000" : "currentColor"} strokeWidth="2" />
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <Button
            onClick={onClick}
            variant={isActive ? "contained" : "outlined"}
            sx={{
                minWidth: '120px',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                color: isActive ? '#000' : 'inherit',
                textTransform: 'none',
                backgroundColor: isActive ? 'primary.main' : 'transparent',
                '&:hover': {
                    backgroundColor: isActive ? 'primary.dark' : 'rgba(255, 87, 34, 0.04)',
                }
            }}
        >
            <svg width="32" height="32" viewBox="0 0 32 32" style={{ color: 'inherit' }}>
                {getLayoutIcon()}
            </svg>
            <span>{layout === 'single' ? 'Single' : layout}</span>
        </Button>
    );
};

export default LayoutButton;