import React, { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    Box,
    ButtonGroup,
    Button,
    TextField,
    IconButton,
    Paper,
    Tooltip,
    CircularProgress,
} from "@mui/material";
import {
    PowerSettingsNew,
    VolumeUp,
    VolumeDown,
    VolumeOff,
    Input
} from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import axios from "axios";
import { getToken } from "../../../services/authentication";
import { updateCachedSplitScreenOption, getActiveCompany } from "../../../services/localStorage";
import LayoutButton from "../../LayoutButton";
import { useSnackbar } from "../../layout/SnackbarContext";

interface SettingsTabProps {
    selectedTv: number;
    tvIds: number[];
    splitScreenOptions: number[];
    rememberPin: boolean;
    wifiSettings: {
        ssid: string;
        password: string;
    };
    onSplitScreenOptionsUpdate: (newOptions: number[]) => void;
}

const API_URL = process.env.REACT_APP_API_URL;

const SettingsTab: React.FC<SettingsTabProps> = ({
    selectedTv,
    tvIds,
    splitScreenOptions: initialSplitScreenOptions,
    rememberPin: initialRememberPin,
    wifiSettings: initialWifiSettings,
    onSplitScreenOptionsUpdate,
}) => {
    const { t } = useTranslation();
    const { showSnackbar } = useSnackbar();

    // Local state for all settings
    const [splitScreenOptions, setSplitScreenOptions] = useState<number[]>(initialSplitScreenOptions);
    const [activeButton, setActiveButton] = useState<number>(initialSplitScreenOptions[selectedTv] || 0);
    const [rememberPin, setRememberPin] = useState<boolean>(initialRememberPin);
    const [wifiSsid, setWifiSsid] = useState<string>(initialWifiSettings.ssid);
    const [wifiPassword, setWifiPassword] = useState<string>(initialWifiSettings.password);
    const [isHdmiLoading, setIsHdmiLoading] = useState<string>('');

    // Update local state when props change
    useEffect(() => {
        setSplitScreenOptions(initialSplitScreenOptions);
        setActiveButton(initialSplitScreenOptions[selectedTv] || 0);
        setRememberPin(initialRememberPin);
        setWifiSsid(initialWifiSettings.ssid);
        setWifiPassword(initialWifiSettings.password);
    }, [selectedTv, initialSplitScreenOptions, initialRememberPin, initialWifiSettings]);

    // Split Screen Settings
    const updateSplitScreenSetting = async (option: number) => {
        try {
            const token: string = getToken();
            const company = getActiveCompany();
            if (!company) return;
            const companyId = company.id;
            const realTvId = tvIds[selectedTv];

            await axios.post(
                `${API_URL}/tv/updateSplitScreen`,
                { companyId, tvId: realTvId, splitScreenOption: option },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const newSplitScreenOptions = [...splitScreenOptions];
            newSplitScreenOptions[selectedTv] = option;
            setSplitScreenOptions(newSplitScreenOptions);
            setActiveButton(option);
            onSplitScreenOptionsUpdate(newSplitScreenOptions);

            // Update cached company data
            updateCachedSplitScreenOption(realTvId, option);
            showSnackbar(t("galleryComponent_splitScreenUpdated"), "success");
        } catch (error) {
            console.error("Error updating split screen setting:", error);
            showSnackbar(t("galleryComponent_splitScreenError"), "error");
        }
    };

    // Remember PIN Settings
    const handleRememberPinChange = async (newValue: boolean) => {
        try {
            const realTvId = tvIds[selectedTv];
            await axios.post(`${API_URL}/tv/updateRememberPin`,
                { tvId: realTvId, rememberPin: newValue },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            );

            setRememberPin(newValue);
            showSnackbar(t("galleryComponent_rememberPinUpdated"), "success");
        } catch (error) {
            console.error("Error updating remember pin:", error);
            showSnackbar(t("galleryComponent_rememberPinError"), "error");
        }
    };

    // WiFi Settings
    const saveWifiSettings = async () => {
        try {
            const token: string = getToken();
            const company = getActiveCompany();
            if (!company) {
                showSnackbar(t("galleryComponent_noActiveCompany"), "error");
                return;
            }
            const realTvId = tvIds[selectedTv];

            await axios.post(
                `${API_URL}/tv/updateWifiSettings`,
                { tvId: realTvId, ssid: wifiSsid, password: wifiPassword },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            showSnackbar(t("galleryComponent_wifiUpdated"), "success");
        } catch (error) {
            console.error("Error saving Wi-Fi settings:", error);
            showSnackbar(t("galleryComponent_wifiError"), "error");
        }
    };

    // HDMI Control
    const sendHdmiCommand = async (command: string) => {
        try {
            setIsHdmiLoading(command);
            const token = getToken();
            const company = getActiveCompany();
            if (!company) {
                showSnackbar(t("galleryComponent_noActiveCompany"), "error");
                return;
            }

            const realTvId = tvIds[selectedTv];

            await axios.post(
                `${API_URL}/tv/hdmiControl`,
                { tvId: realTvId, command },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            showSnackbar(t("galleryComponent_hdmiCommandSent"), "success");
        } catch (error) {
            console.error("Error sending HDMI command:", error);
            showSnackbar(t("galleryComponent_hdmiError"), "error");
        } finally {
            setIsHdmiLoading('');
        }
    };

    const HdmiButton = ({ command, icon, tooltip }: { command: string; icon: React.ReactNode; tooltip: string }) => (
        <Tooltip title={tooltip}>
            <IconButton
                onClick={() => sendHdmiCommand(command)}
                disabled={!!isHdmiLoading}
                sx={{
                    backgroundColor: 'rgba(255, 255, 255, 0.08)',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.12)',
                    },
                    width: { xs: '45px', sm: '56px' },
                    height: { xs: '45px', sm: '56px' },
                    position: 'relative',
                    color: 'white'
                }}
            >
                {isHdmiLoading === command ? (
                    <CircularProgress size={24} color="primary" />
                ) : icon}
            </IconButton>
        </Tooltip>
    );

    return (
        <Grid container spacing={3}>
            {/* Split Screen Options */}
            <Grid item xs={12} md={6}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    {t("galleryComponent_splitScreen")}
                </Typography>
                <Box sx={{
                    display: 'flex',
                    gap: 1,
                    flexWrap: 'wrap',
                    '& .MuiButton-root': {
                        mb: 1
                    }
                }}>
                    <LayoutButton
                        layout="single"
                        isActive={activeButton === 0}
                        onClick={() => updateSplitScreenSetting(0)}
                    />
                    <LayoutButton
                        layout="2x2"
                        isActive={activeButton === 4}
                        onClick={() => updateSplitScreenSetting(4)}
                    />
                    <LayoutButton
                        layout="3x3"
                        isActive={activeButton === 6}
                        onClick={() => updateSplitScreenSetting(6)}
                    />
                    <LayoutButton
                        layout="2:1"
                        isActive={activeButton === 2}
                        onClick={() => updateSplitScreenSetting(2)}
                    />
                </Box>
            </Grid>

            {/* Remember PIN Options */}
            <Grid item xs={12} md={6}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    {t("galleryComponent_rememberPin")}
                </Typography>
                <ButtonGroup variant="contained" aria-label="remember pin button group">
                    <Button
                        onClick={() => handleRememberPinChange(true)}
                        variant={rememberPin ? "contained" : "outlined"}
                        sx={{
                            color: rememberPin ? '#000' : 'inherit',
                            '&:hover': {
                                backgroundColor: rememberPin ? 'primary.dark' : 'rgba(255, 87, 34, 0.04)',
                            }
                        }}
                    >
                        {t("dialog_yes")}
                    </Button>
                    <Button
                        onClick={() => handleRememberPinChange(false)}
                        variant={!rememberPin ? "contained" : "outlined"}
                        sx={{
                            color: !rememberPin ? '#000' : 'inherit',
                            '&:hover': {
                                backgroundColor: !rememberPin ? 'primary.dark' : 'rgba(255, 87, 34, 0.04)',
                            }
                        }}
                    >
                        {t("dialog_no")}
                    </Button>
                </ButtonGroup>
            </Grid>

            {/* HDMI Controls */}
            <Grid item xs={12}>
                <Paper
                    elevation={3}
                    sx={{
                        p: 3,
                        backgroundColor: 'rgba(0, 0, 0, 0.2)',
                        border: '1px solid rgba(255, 255, 255, 0.1)'
                    }}
                >
                    <Typography variant="h6" sx={{ mb: 3 }}>
                        {t("galleryComponent_hdmiControl")}
                    </Typography>

                    <Grid container spacing={2}>
                        {/* Power Controls */}
                        <Grid item xs={12} sm={6} md={3}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                                <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                    {t("galleryComponent_power")}
                                </Typography>
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <HdmiButton
                                        command="POWER_ON"
                                        icon={<PowerSettingsNew color="success" />}
                                        tooltip={t("galleryComponent_powerOn")}
                                    />
                                    <HdmiButton
                                        command="POWER_OFF"
                                        icon={<PowerSettingsNew color="error" />}
                                        tooltip={t("galleryComponent_powerOff")}
                                    />
                                </Box>
                            </Box>
                        </Grid>

                        {/* Volume Controls */}
                        <Grid item xs={12} sm={6} md={3}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                                <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                    {t("galleryComponent_volume")}
                                </Typography>
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <HdmiButton
                                        command="VOLUME_UP"
                                        icon={<VolumeUp />}
                                        tooltip={t("galleryComponent_volumeUp")}
                                    />
                                    <HdmiButton
                                        command="VOLUME_DOWN"
                                        icon={<VolumeDown />}
                                        tooltip={t("galleryComponent_volumeDown")}
                                    />
                                    <HdmiButton
                                        command="MUTE"
                                        icon={<VolumeOff />}
                                        tooltip={t("galleryComponent_mute")}
                                    />
                                </Box>
                            </Box>
                        </Grid>

                        {/* Input Selection */}
                        <Grid item xs={12} sm={12} md={6}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                                <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                    {t("galleryComponent_input")}
                                </Typography>
                                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', justifyContent: 'center' }}>
                                    {[1, 2, 3].map((input) => (
                                        <Button
                                            key={input}
                                            variant="contained"
                                            onClick={() => sendHdmiCommand(`INPUT_HDMI${input}`)}
                                            disabled={!!isHdmiLoading}
                                            startIcon={<Input />}
                                            sx={{
                                                minWidth: '120px',
                                                backgroundColor: 'rgba(255, 255, 255, 0.08)',
                                                '&:hover': {
                                                    backgroundColor: 'rgba(255, 255, 255, 0.12)',
                                                }
                                            }}
                                        >
                                            HDMI {input}
                                        </Button>
                                    ))}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>

            {/* Wi-Fi Settings */}
            <Grid item xs={12}>
                <Box sx={{ mt: 3 }}>
                    <Typography variant="h6">
                        {t("galleryComponent_wifiSettings")}
                    </Typography>

                    <Box sx={{ mt: 3 }}>
                        <Typography variant="subtitle2" sx={{ color: "text.disabled", mb: 2 }}>
                            {t("galleryComponent_wifiName")}
                        </Typography>
                        <TextField
                            placeholder={t("galleryComponent_enterWifiName")}
                            fullWidth
                            variant="outlined"
                            value={wifiSsid}
                            onChange={(e) => setWifiSsid(e.target.value)}
                            sx={{
                                backgroundColor: "#3D3D3D",
                                "& .MuiOutlinedInput-input": {
                                    color: "white",
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "white",
                                },
                                "& .MuiOutlinedInput-root": {
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "primary.main",
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "primary.main",
                                    },
                                },
                            }}
                        />
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <Typography variant="subtitle2" sx={{ color: "text.disabled", mb: 2 }}>
                            {t("galleryComponent_wifiPassword")}
                        </Typography>
                        <TextField
                            type="password"
                            placeholder={t("galleryComponent_enterWifiPassword")}
                            fullWidth
                            variant="outlined"
                            value={wifiPassword}
                            onChange={(e) => setWifiPassword(e.target.value)}
                            sx={{
                                backgroundColor: "#3D3D3D",
                                "& .MuiOutlinedInput-input": {
                                    color: "white",
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "white",
                                },
                                "& .MuiOutlinedInput-root": {
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "primary.main",
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "primary.main",
                                    },
                                },
                            }}
                        />
                    </Box>

                    <Button
                        onClick={saveWifiSettings}
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3 }}
                    >
                        {t("galleryComponent_saveWifiSettings")}
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};

export default SettingsTab;